@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Overpass:400,700|Roboto:400,700");
:root {
  --otherly-white:  #fff;
  --otherly-black:  #000;
  --otherly-red:    #F2391D;
  --otherly-perpel: #b55fcc;
  --otherly-teal:   #37cba7;
  --otherly-font-size: 16px;
  --otherly-font-family: 'Overpass', sans-serif;
  --otherly-font-roboto: 'Roboto', sans-serif;
  font-size: var(--otherly-font-size);
  font-family: var(--otherly-font-fmaily);
  font-weight: 400;
  line-height: 1.5;
  box-sizing: border-box; }

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: var(--otherly-white);
  color: var(--otherly-black);
  overflow-x: hidden; }

h1, h2, h3, h4, h5, h6 {
  font-family: var(--otherly-font-family);
  line-height: 1.5;
  font-weight: 400;
  margin: 0; }

h1 {
  font-size: 3rem;
  margin-top: 1rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1rem; }

p {
  margin: 0;
  font-family: var(--otherly-font-roboto); }

.bold-underline {
  position: relative; }
  .bold-underline:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 10px;
    width: 100px;
    background: var(--otherly-perpel); }

section {
  position: relative; }
  section .container {
    width: clamp(280px, 60%, 800px);
    margin: 0 auto;
    padding: 40px 0; }
    section .container a {
      color: var(--otherly-red);
      text-decoration: none; }
      section .container a:hover, section .container a:focus {
        text-decoration: underline; }

.intro-section {
  height: 100vh;
  background: var(--otherly-perpel);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .intro-section video {
    width: 100vw;
    height: auto; }
    @media screen and (min-width: 768px) {
      .intro-section video {
        width: auto;
        height: 100vh; } }

.main-description {
  background: var(--otherly-teal);
  color: var(--otherly-black);
  min-height: 100vh;
  padding: 3rem 1rem; }
  .main-description .content {
    margin-top: 4rem; }
    .main-description .content p:first-child {
      font-size: 1.5rem;
      line-height: 1.3; }
      @media screen and (min-width: 768px) {
        .main-description .content p:first-child {
          font-size: 2rem; } }
    .main-description .content p + p {
      margin-top: 2rem;
      font-size: 1.5rem; }
    .main-description .content a {
      text-decoration: none;
      background-color: var(--otherly-perpel);
      line-height: 1.5em;
      color: var(--otherly-white);
      padding: 5px 10px 0px; }
      .main-description .content a:hover {
        text-decoration: none;
        background-color: rgba(181, 95, 204, 0.8); }
    .main-description .content p {
      font-family: var(--otherly-font-family); }

.logos {
  padding: 3rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (min-width: 768px) {
    .logos {
      width: 500px; } }
  .logos-intro {
    text-align: center;
    width: 100%;
    flex: 1 0 auto;
    margin: 0 0 1.2rem;
    color: var(--otherly-white); }
  .logos-nfb, .logos-pov {
    width: 50%; }
    .logos-nfb img, .logos-pov img {
      height: 50px; }
      @media screen and (min-width: 768px) {
        .logos-nfb img, .logos-pov img {
          height: 75px; } }
  .logos-pov {
    text-align: left; }
  .logos-nfb {
    text-align: right; }

@media screen and (min-width: 768px) {
  .posts .post {
    margin-left: 3rem; } }

.posts .post-image {
  margin-bottom: 1rem; }
  @media screen and (min-width: 768px) {
    .posts .post-image {
      width: 500px; } }
  .posts .post-image img {
    width: 100%; }

.posts .post + .post {
  margin-top: 3rem; }

.posts .post-title {
  font-size: 1.5rem; }

.about p + h2 {
  margin-top: 4rem; }

.igspinner {
  position: relative;
  display: flex;
  width: 170px;
  height: 170px;
  text-align: center;
  justify-content: center;
  align-items: center; }

.igspinner-border {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  padding: 0px;
  -webkit-animation: rotate 5.5s infinite linear;
  animation: rotate 5.5s infinite linear; }

.igspinner-border-segment {
  position: absolute;
  top: -10px;
  left: -10px; }

.igspinner-border-segmentContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

.igspinner-borderMask {
  position: absolute;
  left: -10px;
  right: -10px;
  bottom: -10px;
  top: -10px; }

.igspinner-borderMask-gfx circle {
  stroke: #141414;
  /* must match bg—though wish I could <mask> */ }

.igspinner-border-segmentContainer:nth-child(1) {
  -webkit-transform: rotate(12deg);
  transform: rotate(12deg); }

.igspinner-border-segmentContainer:nth-child(1) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s; }

.igspinner-border-segmentContainer:nth-child(2) {
  -webkit-transform: rotate(24deg);
  transform: rotate(24deg); }

.igspinner-border-segmentContainer:nth-child(2) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }

.igspinner-border-segmentContainer:nth-child(3) {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg); }

.igspinner-border-segmentContainer:nth-child(3) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s; }

.igspinner-border-segmentContainer:nth-child(4) {
  -webkit-transform: rotate(48deg);
  transform: rotate(48deg); }

.igspinner-border-segmentContainer:nth-child(4) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

.igspinner-border-segmentContainer:nth-child(5) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg); }

.igspinner-border-segmentContainer:nth-child(5) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s; }

.igspinner-border-segmentContainer:nth-child(6) {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg); }

.igspinner-border-segmentContainer:nth-child(6) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

.igspinner-border-segmentContainer:nth-child(7) {
  -webkit-transform: rotate(84deg);
  transform: rotate(84deg); }

.igspinner-border-segmentContainer:nth-child(7) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s; }

.igspinner-border-segmentContainer:nth-child(8) {
  -webkit-transform: rotate(96deg);
  transform: rotate(96deg); }

.igspinner-border-segmentContainer:nth-child(8) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

.igspinner-border-segmentContainer:nth-child(9) {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg); }

.igspinner-border-segmentContainer:nth-child(9) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s; }

.igspinner-border-segmentContainer:nth-child(10) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg); }

.igspinner-border-segmentContainer:nth-child(10) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s; }

.igspinner-border-segmentContainer:nth-child(11) {
  -webkit-transform: rotate(132deg);
  transform: rotate(132deg); }

.igspinner-border-segmentContainer:nth-child(11) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s; }

.igspinner-border-segmentContainer:nth-child(12) {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg); }

.igspinner-border-segmentContainer:nth-child(12) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

.igspinner-border-segmentContainer:nth-child(13) {
  -webkit-transform: rotate(156deg);
  transform: rotate(156deg); }

.igspinner-border-segmentContainer:nth-child(13) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s; }

.igspinner-border-segmentContainer:nth-child(14) {
  -webkit-transform: rotate(168deg);
  transform: rotate(168deg); }

.igspinner-border-segmentContainer:nth-child(14) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s; }

.igspinner-border-segmentContainer:nth-child(15) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.igspinner-border-segmentContainer:nth-child(15) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s; }

.igspinner-border-segmentContainer:nth-child(16) {
  -webkit-transform: rotate(192deg);
  transform: rotate(192deg); }

.igspinner-border-segmentContainer:nth-child(16) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }

.igspinner-border-segmentContainer:nth-child(17) {
  -webkit-transform: rotate(204deg);
  transform: rotate(204deg); }

.igspinner-border-segmentContainer:nth-child(17) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.85s;
  animation-delay: 0.85s; }

.igspinner-border-segmentContainer:nth-child(18) {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg); }

.igspinner-border-segmentContainer:nth-child(18) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s; }

.igspinner-border-segmentContainer:nth-child(19) {
  -webkit-transform: rotate(228deg);
  transform: rotate(228deg); }

.igspinner-border-segmentContainer:nth-child(19) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 0.95s;
  animation-delay: 0.95s; }

.igspinner-border-segmentContainer:nth-child(20) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg); }

.igspinner-border-segmentContainer:nth-child(20) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1s;
  animation-delay: 1s; }

.igspinner-border-segmentContainer:nth-child(21) {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg); }

.igspinner-border-segmentContainer:nth-child(21) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1.05s;
  animation-delay: 1.05s; }

.igspinner-border-segmentContainer:nth-child(22) {
  -webkit-transform: rotate(264deg);
  transform: rotate(264deg); }

.igspinner-border-segmentContainer:nth-child(22) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s; }

.igspinner-border-segmentContainer:nth-child(23) {
  -webkit-transform: rotate(276deg);
  transform: rotate(276deg); }

.igspinner-border-segmentContainer:nth-child(23) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s; }

.igspinner-border-segmentContainer:nth-child(24) {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg); }

.igspinner-border-segmentContainer:nth-child(24) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s; }

.igspinner-border-segmentContainer:nth-child(25) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg); }

.igspinner-border-segmentContainer:nth-child(25) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s; }

.igspinner-border-segmentContainer:nth-child(26) {
  -webkit-transform: rotate(312deg);
  transform: rotate(312deg); }

.igspinner-border-segmentContainer:nth-child(26) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s; }

.igspinner-border-segmentContainer:nth-child(27) {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg); }

.igspinner-border-segmentContainer:nth-child(27) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1.35s;
  animation-delay: 1.35s; }

.igspinner-border-segmentContainer:nth-child(28) {
  -webkit-transform: rotate(336deg);
  transform: rotate(336deg); }

.igspinner-border-segmentContainer:nth-child(28) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s; }

.igspinner-border-segmentContainer:nth-child(29) {
  -webkit-transform: rotate(348deg);
  transform: rotate(348deg); }

.igspinner-border-segmentContainer:nth-child(29) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1.45s;
  animation-delay: 1.45s; }

.igspinner-border-segmentContainer:nth-child(30) {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg); }

.igspinner-border-segmentContainer:nth-child(30) .igspinner-border-segment {
  -webkit-transform-origin: 180px 50%;
  transform-origin: 180px 50%;
  -webkit-animation: scale 3s infinite linear;
  animation: scale 3s infinite linear;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s; }

.igspinner-imageMask {
  background: #ccc;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  width: 150px;
  height: 150px;
  display: inline-block;
  vertical-align: middle; }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  18% {
    -webkit-transform: scale(0.01, 0.01);
    transform: scale(0.01, 0.01); }
  33% {
    -webkit-transform: scale(0.01, 0.01);
    transform: scale(0.01, 0.01); }
  51% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes scale {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  18% {
    -webkit-transform: scale(0.01, 0.01);
    transform: scale(0.01, 0.01); }
  33% {
    -webkit-transform: scale(0.01, 0.01);
    transform: scale(0.01, 0.01); }
  51% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }
